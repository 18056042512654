import React from "react"
import Head from "next/head"

export default function HeadTitle({
  title,
}: {
  title: string | undefined
}): JSX.Element {
  const titleText =
    title && `${title} - Observatoire National des Violences en Santé`
  return (
    <>
      <Head>
        <title>{titleText}</title>
      </Head>
    </>
  )
}
